import {
  contactsInsertInputSchema,
  type ContactsInsertPosSchema,
} from "@mono/validation/lib/Contacts";
import { useSettingsStore } from "./settings";
import { usePosStore } from "./pos";
import { useUtils } from "~/composables/useUtils";

export type ContactSchema = APIOutputs["contacts"]["getAllPOS"]["data"][number];
export type PendingContactsSchema = ContactSchema & {
  isNotSynced?: boolean;
  countryId: string;
};

const { isNetworkError } = useUtils();

export const useContactStore = defineStore("contact", () => {
  // states
  const contacts = ref<ContactSchema[]>([]);
  const pendingContacts = ref<PendingContactsSchema[]>([]);
  const lastFetchedAt = ref<number>();

  // computes
  const allContacts = computed(() =>
    contacts.value.concat(pendingContacts.value)
  );

  // composables
  const { cache } = useIndexDB();

  // fetchData handler
  const fetchData = async () => {
    const { trpcClient } = useTrpcClient();
    const { settings } = useSettingsStore();
    const { logout } = useAuthStore();

    let fetchData: ContactSchema[] | undefined;

    try {
      if (!settings) {
        throw new Error("Settings not found");
      }

      const { data } = await trpcClient.contacts.getAllPOS.query();

      // cache data
      await cache.contacts.saveMany(data);

      fetchData = data;
      lastFetchedAt.value = Date.now();
    } catch (error) {
      if (isNetworkError(error)) {
        const cachedData = await cache.contacts.getAll();

        fetchData = cachedData;
      } else {
        logout();
      }
    } finally {
      if (fetchData) {
        contacts.value = fetchData;
      }
    }
  };

  // submitContact handler
  const submitContact = async (contact: ContactsInsertPosSchema) => {
    const posStore = usePosStore();

    if (!posStore.pointOfSale) {
      throw new Error("Point of sale not found");
    }

    // this will give a console warning when not in https, safe to ignore
    const contactId = crypto.randomUUID();

    // if contact already exists, recursively call submitContact
    if (allContacts.value.find((c) => c.id === contactId)) {
      submitContact(contact);
      return;
    }

    try {
      const data: PendingContactsSchema = {
        id: contactId,

        contactName: contact.contactName,
        contactPrimaryEmail: contact.contactPrimaryEmail,
        contactPrimaryPhone: contact.contactPrimaryPhone,
        contactTaxNumber: contact.contactTaxNumber,
        contactCrNumber: contact.contactCrNumber ?? null,
        address: contact.address ?? null,

        countryId: posStore.pointOfSale.organization.countryId,
        orgId: posStore.pointOfSale.orgId,
        accountId: posStore.pointOfSale.contact!.accountId,

        isActive: true,
        isCustomer: true,

        contactNameTranslations: {},
        contactOrgName: "",
        contactOrgNameTranslations: {},

        contactSecondaryEmail: "",
        contactSecondaryPhone: "",

        city: "",
        cityTranslations: {},
        addressTranslations: {},
        zipcode: "",
        isBusiness: false,
        isNotSynced: true,
      };

      const schemaValidation =
        await contactsInsertInputSchema.safeParseAsync(data);

      if (!schemaValidation.success) {
        return;
      }

      // cache contact
      await cache.pendingContacts.add(data);

      pendingContacts.value.push(data);

      // sync invoices after 15 seconds
      setTimeout(async () => {
        await syncContacts();
      }, 1000 * 15);

      return data;
    } catch (error) {
      console.error("Error submitting contact:", error);
    }
  };

  // getUnSyncedContacts handler
  const getUnSyncedContacts = async () => {
    const cachedContacts = await cache.pendingContacts.getAll();
    pendingContacts.value = cachedContacts;

    return cachedContacts;
  };

  const clearUnSyncedContacts = async () => {
    await cache.pendingContacts.clear();

    pendingContacts.value = [];
  };

  // syncContacts handler
  const syncContacts = async () => {
    const { trpcClient } = useTrpcClient();

    // run the online check
    const { isOnline } = useNetworkCheck();
    await isOnline();

    // get all cached contacts
    const pendingContacts = await getUnSyncedContacts();

    // if no pending contacts, return
    if (!pendingContacts?.length) {
      await fetchData();
      return;
    }

    try {
      await trpcClient.contacts.createMany.mutate({
        isCustomer: true,
        data: pendingContacts.map((contact) => ({
          ...contact,
          contactCrNumber: contact.contactCrNumber ?? undefined,
          isBusiness: false,
          contactNameTranslations: contact.contactNameTranslations ?? undefined,
          contactOrgNameTranslations:
            contact.contactOrgNameTranslations ?? undefined,
          addressTranslations: contact.addressTranslations ?? undefined,
          cityTranslations: contact.cityTranslations ?? undefined,
        })),
      });

      await Promise.all([fetchData(), clearUnSyncedContacts()]);
    } catch (error) {
      if (isNetworkError(error)) {
        await fetchData();
        return;
      }

      throw error;
    }
  };

  // clear handler
  const clear = async () => {
    await syncContacts();
    await Promise.all([cache.contacts.clear(), clearUnSyncedContacts()]);
    contacts.value = [];
  };

  // init handler
  const init = async () => {
    await syncContacts();
  };

  return {
    // getters
    contacts,
    pendingContacts,
    allContacts,
    lastFetchedAt,
    // setters
    submitContact,
    syncContacts,
    fetchData,
    init,
    clear,
  };
});
