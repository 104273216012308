<script setup lang="ts">
import { ref, toRaw, useSlots, computed } from "vue";
import { mdiDotsVertical } from "@mdi/js";
import MMenu from "../components/MMenu.vue";
import MBtn from "../components/MBtn.vue";
import MCard from "../components/MCard.vue";
import MList from "../components/MList.vue";
import MListItem from "../components/MListItem.vue";

interface Props {
  horizontal?: boolean;
}

const { horizontal } = defineProps<Props>();

const slots = useSlots();

const slotsCount = computed(() => Object.keys(toRaw(slots)).length);
const mobileMenu = ref(false);
</script>

<template>
  <div>
    <div
      :class="
        !!horizontal ? 'hidden md:flex items-center gap-x-4' : 'hidden md:block'
      "
    >
      <template
        v-for="(_, slot) in $slots"
        :key="slot"
      >
        <slot :name="slot" />
        <span
          v-if="Number(slot) !== slotsCount && !horizontal"
          class="px-2"
        />
      </template>
    </div>
    <div
      v-if="slotsCount"
      class="md:hidden"
    >
      <MMenu
        v-model="mobileMenu"
        :closeOnContentClick="false"
      >
        <template #activator="{ props }">
          <MBtn
            v-bind="props"
            color="default"
            size="small"
            icon
            variant="tonal"
          >
            <MIcon :icon="mdiDotsVertical" />
          </MBtn>
        </template>
        <MCard>
          <MList>
            <template
              v-for="(_, slot) in $slots"
              :key="slot"
            >
              <MListItem>
                <slot :name="slot" />
              </MListItem>
            </template>
          </MList>
        </MCard>
      </MMenu>
    </div>
  </div>
</template>
